document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('shopify-app-init')

  if (!el) {
    return
  }

  var data = el.dataset;

  var AppBridge = window['app-bridge'];
  var createApp = AppBridge.default;
  window.app = createApp({
    apiKey: data.apiKey,
    shopOrigin: data.shopOrigin,
  });

  var actions = AppBridge.actions;
  var TitleBar = actions.TitleBar;
  TitleBar.create(app, {
    title: data.page,
  });
});
